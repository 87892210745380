import useFetch from "../api/useFetch";
import Loading from "./Loading";
import ShipmentLine from "./ShipmentLine"
import '../css/products.css'

const Shipments = () => {
      const { result: shipments, isLoading: shipmentsLoading, error: shipmentsError } = useFetch('/get_martelle_shipments',[]);
      

  return (
    
    <>
  <div className="products">
  <div div className="title">
          SHIPMENTS
        </div>
   <div className="table">
    <div className="table-header">
      <div className="date">Creation Date</div>
      <div className="small">Tracking Number</div>
      <div className="large">Carrier</div>
      <div className="small">Status</div>
      <div className="small">Tracking</div>
      <div className="action">Orders</div>
    </div>
    <div className="table-body">
            {shipmentsLoading && <div className='table-placeholder'><Loading /></div>}
            {shipmentsError && <div>{shipmentsError}</div>}
            {!shipmentsLoading &&  shipments && shipments.items.length > 0 && shipments.items.map((shipment) => <ShipmentLine carrier={shipment.carrier} shipment_id={shipment.id} tracking_number={shipment.tracking_number} shipment_date={shipment.creation_date} shipment_status={shipment.status}  tracking_url={shipment.tracking_link}/>)}
          </div>
    </div>
   </div>
   </>
  )
}

export default Shipments