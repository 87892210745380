import useFetch from "../api/useFetch";
import Loading from "./Loading";
import RouteLine from "./RouteLine";
import { useState } from "react";
import '../css/products.css'

const Routes = () => {
  const [showSubroutes, setShowSubroutes] = useState([]);
  const { result: routes, isLoading: routesLoading, error: routesError } = useFetch(`/routes`, []);

  return (
    <>
    <div className="main-infos">
      <div className='main-info active'>
        <i className="fas fa-road"></i>Create New Route
      </div>
    </div>
    <div className="products">
      <div className="table">
        <div className="table-header">
          <div className="table-row">
          <div className="small"></div>
            <div className="small">Sourcing</div>
            <div className="small">Destination</div>
            <div className="small">Segment</div>
            <div className="small">Status</div>
            <div className="small">Algorithm</div>
            <div className="small">Deployed</div>
            <div className="small">Deploy %</div>
            <div className="small">Products</div>
            <div className="medium">Available</div>
            <div className="small">Listings</div>
            <div className="small">Sales Count</div>
            <div className="small">Product With Sales</div>
          </div>
        </div>
        <div className="table-body">
          {routesLoading && <div className='table-placeholder'><Loading /></div>}
          {routesError && <div>{routesError}</div>}
          {!routesLoading && routes && routes.map((route) => (
            <RouteLine
              id={route.id}
              key={route.id}
              sourcing={route.sourcing_marketplace ? route.sourcing_marketplace.name : 'Inventory'}
              destination={route.destination_marketplace.domain}
              segment={route.segment ? route.segment : 'all'}
              status={route.status}
              algorithm={route.pricing_algorithm}
              deployed={route.deployed ? 'yes' : 'no'}
              products={route.products_count}
              products_available={route.products_available_count}
              listings={route.listings_count}
              sales_count={route.sales_count}
              products_with_sales={route.products_with_sales}
              parent_id={route.parent_route_id}
              deployment_percent={route.deployment_percent}
              showSubroutes={showSubroutes}
              setShowSubroutes={setShowSubroutes}
            />

          ))}
        </div>
      </div>
    </div>
    </>
  );
}

export default Routes;
