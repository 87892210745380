import { Link } from "react-router-dom";
const NotificationsPanel = ({notifications, setShowNotificationsPanel}) => {

  const closePanel = () => {
    setShowNotificationsPanel(false)
  }
  return (
    <div className='notifications-panel' onClick={closePanel}>
        {notifications.total ===  0 && <span>No notifications</span>}
        {notifications.to_buy_count > 0 && <Link to='/to_buy'>{notifications.to_buy_count} orders to buy</Link>}
        {notifications.to_pickup_count > 0 && <Link to='/delivery'>{notifications.to_pickup_count} orders to pick up</Link>}
        {notifications.to_print_count > 0 && <Link to='/to_print'>{notifications.to_print_count} orders to print</Link>}
        {notifications.to_ship_count > 0 && <Link to='/to_ship'>{notifications.to_ship_count} orders to ship</Link>}
        {notifications.needs_customer_response_count > 0 && <Link to='/quality'>{notifications.needs_customer_response_count} requests to answer</Link>}
        {notifications.to_find_count > 0 && <Link to='/to_find'>{notifications.to_find_count} orders to find</Link>}
        {notifications.needs_cancel_attribution_count > 0 && <Link to='/cancel_refunds'>{notifications.needs_cancel_attribution_count} cancelled requests to process</Link>}
        {notifications.needs_action_count > 0 && <Link to='/actions'>{notifications.needs_action_count} actions to perform</Link>}
        {notifications.to_request_feedback_count > 0 && <Link to='/quality'>{notifications.to_request_feedback_count} orders to request feedback</Link>}
        {notifications.needs_late_reason_count > 0 && <Link to='/late_orders'>{notifications.needs_late_reason_count} orders with late delivery</Link>}
    </div>
  )
}

export default NotificationsPanel;