import React from 'react'

const RouteLine = ({id, sourcing, destination, segment, status, algorithm, deployed, products, products_available, listings, parent_id,sales_count, products_with_sales, showSubroutes, setShowSubroutes, deployment_percent}) => {
  
  return (
    <>


      {(segment === 'all' || showSubroutes.includes(parent_id)) && (
        <div className="table-row" data-id={id}>
          <div style={{fontWeight: 'bold'}} className="small">{showSubroutes.includes(id) && segment === 'all'  && sourcing !== 'Inventory' &&  <i onClick={() => setShowSubroutes(showSubroutes.filter(route => route !== id))} className="fas fa-minus-square"></i>}{!showSubroutes.includes(id) && segment === 'all' &&  sourcing !== 'Inventory' &&  <i onClick={() => setShowSubroutes([...showSubroutes, id])} className='fas fa-plus-square'></i>}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{sourcing}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{destination}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{segment}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{status}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{algorithm}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{deployed}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{(deployment_percent * 100).toFixed(0)}%</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{products}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="medium">{products_available} ({products && products > 0 && (products_available/products * 100).toFixed(1)}%)</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{listings} ({listings && products_available && products_available > 0 && (listings/products_available * 100).toFixed(1)}%)</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{sales_count}</div>
          <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{products_with_sales}</div>
        </div>
      )}
    </>
  )
}

export default RouteLine